import {useContext} from 'react';
import {Container,Row,Col,Table} from 'react-bootstrap'
import { UALContext } from "ual-reactjs-renderer";

const WalletInstructions = ({network}) => {

    let ual = useContext(UALContext);

    if ( ual.activeAuthenticator && ual.activeAuthenticator.getName() === 'wax' )
    {
        return (
            <Container>
                <Row className="py-5 gx-5 justify-content-md-center">
                    <Col xs={12} md={8}>
                        <h3>Wax Cloud Wallet</h3>
                        <ol>
                            <li>Visit your <a target="_blank" rel="noreferrer" href="https://wallet.wax.io/">WAX Cloud Wallet Dashboard</a></li>
                            <li>At the bottom of your dashboard, under "Tokens" click on the <strong>Manage</strong> button.</li>
                            <li>Click the <strong>Add Custom Token</strong> button.</li>
                            <li>Copy and paste the following information:</li>
                        </ol>
                        <Table striped bordered hover className="table-responsive">
                            <thead>
                                <tr>
                                    <th colSpan="2">{process.env.REACT_APP_TOKEN_NAME} Info</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Token Symbol</th>
                                    <td>{process.env.REACT_APP_TOKEN_SYMBOL}</td>
                                </tr>
                                <tr>
                                    <th>Decimal Places</th>
                                    <td>{process.env.REACT_APP_TOKEN_DECIMALS}</td>
                                </tr>
                                <tr>
                                    <th>Token Contract</th>
                                    <td>{process.env.REACT_APP_CONTRACT_CODE}</td>
                                </tr>
                                <tr>
                                    <th>Token Name</th>
                                    <td>{process.env.REACT_APP_TOKEN_NAME}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <span className="d-none d-md-block">
                                            <strong>Token Image</strong><br />
                                            <span className="">{process.env.REACT_APP_TOKEN_IMG}</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <p>After completing the information above, <strong>click the checkmark</strong> next to the Token Symbol field.</p>
                        <p>Finally, click the <strong>Done</strong> button. You should now see <strong>{process.env.REACT_APP_TOKEN_NAME}</strong> listed in your tokens.</p>
                    </Col>
                </Row>
            </Container>
        )
    }
    else if ( ual.activeAuthenticator && ual.activeAuthenticator.getName() === 'anchor' ) {

        return (
            <Container>
                <Row className="py-5 gx-5 justify-content-md-center">
                    <Col xs={12} md={8}>
                        <h3>Anchor Wallet</h3>
                        <ol>
                            <li>Login to Anchor wallet and visit the "Tools" section on the left sidebar.</li>
                            <li>Make sure the correct network is selected (top left of the "Tools" section)</li>
                            <li>Click on <strong>Manage Tracked Tokens</strong></li>
                            <li>Click the <strong>Add Custom Token</strong> button.</li>
                            <li>Copy and paste the following information:</li>
                        </ol>
                        <Table striped bordered hover className="table-responsive">
                            <thead>
                                <tr>
                                    <th colSpan="2">{process.env.REACT_APP_TOKEN_NAME} Info</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Contract Account Name</th>
                                    <td>{process.env.REACT_APP_CONTRACT_CODE}</td>
                                </tr>
                                <tr>
                                    <th>Contract Asset Symbol</th>
                                    <td>{process.env.REACT_APP_TOKEN_SYMBOL}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <p>After completing the information above, click <strong>Add Custom Token</strong>.</p>
                        <p>You should now see <strong>{process.env.REACT_APP_TOKEN_NAME}</strong> listed in your tracked tokens.</p>
                    </Col>
                </Row>
            </Container>
        )

    }
    else {
        return (
            <Container></Container>
        )
    }

}

export default WalletInstructions