import { Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import './NotFound.css'

const NotFound = () => {

  return (
    <Container fluid className="px-0">
        <Container fluid className="px-0 not-found-hero">
            <Container className="pt-5">
                <h1 className="pt-5">Not Found</h1>
                <p className="text-center description">This page doesn't exist. Please check the address and try again.</p>
                <div className="text-center">
                  <LinkContainer to='/'>
                    <button className='btn btn-primary'>Back to Safety</button>
                  </LinkContainer>
                </div>
            </Container>
        </Container>
    </Container>
  )
}

export default NotFound
