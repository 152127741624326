import { Container } from 'react-bootstrap'
import { Route, Switch } from 'react-router-dom'

// Layout
import Layout from './layout/Layout'

// pages
// import Home from './pages/Home'
// import About from './pages/About'
import Crowdsale from './pages/Crowdsale'
import Rewards from './pages/Rewards'
// import RewardsBroken from './pages/RewardsBroken'
import Redeem from './pages/Redeem'
import NotFound from './pages/NotFound'

  const App = ({rpc, chain}) => {
    return (
      <Layout chain={chain}>
        <Container fluid className="px-0">
          <Switch>
            <Route path='/' exact>
              <Crowdsale rpc={rpc} />
            </Route>
            <Route path='/crowdsale'>
              <Crowdsale rpc={rpc} />
            </Route>
            <Route path='/rewards'>
              <Rewards rpc={rpc} />
            </Route>
            <Route path='/redeem'>
              <Redeem rpc={rpc} />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Container>
      </Layout>
    )
  


}

export default App
