import { React, useContext, useEffect, useState } from 'react';
import './Redeem.css'

import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

import { UALContext } from "ual-reactjs-renderer";
import { Asset } from '@greymass/eosio';

import {Container,Row,Col,Button,Image,Modal,Card,Alert,Spinner} from 'react-bootstrap'

import WalletInstructions from '../components/WalletInstructions'

// import {getLowerBound,getUpperBound} from '../lib/queryBounds'

const Redeem = ({rpc}) => {

    let ual = useContext(UALContext);

    const [orders, setOrders] = useState([]);
    const [state, setState] = useState({balance:0, tokenBalance:0});
    const [alertState, setAlertState] = useState({type:'danger',msg:'',show:false});
    const [modalState, setModalState] = useState({type:'success',title:'title',msg:'',show:false, payload: {}, onConfirm: ()=>{}});
    const [modalLoadingState, setModalLoadingState] = useState(false);
    const [lastTxn, setLastTxn] = useState();

    let user = (ual.activeUser && ual.activeUser.accountName) ? ual.activeUser.accountName : '';

    let unitLabel = process.env.REACT_APP_UNIT_LABEL;
    if ( ! unitLabel ) { unitLabel = 'tokens'; }

    // page content
    const pageTitle = 'Redeem ' + process.env.REACT_APP_TOKEN_NAME
    const pageDescription = process.env.REACT_APP_TOKEN_REDEEM_DESCRIPTION

    const AlertDismissible = () => {
        let newAlertState = Object.assign({},alertState);     

        const handleClose = () => { newAlertState.show = false; setAlertState(newAlertState);};
        const msg = alertState.msg.replace('Error: assertion failure with message: ','');
      
        if (alertState.show) {
            return (
                <Modal show={alertState.show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Oops! Something Happened.</Modal.Title>
                </Modal.Header>
                <Modal.Body>{msg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    Close
                    </Button>
                </Modal.Footer>
                </Modal>
            );
        }
        return <></>;
    }

    const ConfirmOrderModal = () => {

        const handleClose = () => { let newModalState = Object.assign({},modalState); newModalState.show = false; setModalState(newModalState);};
        const handleConfirm = async () => { if ( await modalState.onConfirm( modalState.payload ) ) { handleClose(); } };
        const msg = modalState.msg;
      
        if (modalState.show) {
            return (
                <Modal show={modalState.show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalState.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{msg}</p>
                    <h5>Redemption Order #{modalState.payload.orderid}</h5>
                    <form autocomplete="off">
                        <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">Confirm Order ID:</label>
                            <input type="text" className="form-control" id="order-id" defaultValue={modalState.orderid} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="recipient-name" className="col-form-label">Shipping Postal Code:</label>
                            <input type="text" className="form-control" id="postalcode" defaultValue={modalState.postalcode} />
                        </div>
                    </form>
                    <div className="my-3">
                        <Alert variant="danger" show={modalState.error ? true : false}>{modalState.error}</Alert>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={ () => { setModalLoadingState(true); handleConfirm(); }} disabled={modalLoadingState}>
                        {
                            modalLoadingState ? (
                                <Container className="pe-2 d-inline">
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Container>
                            ) : null
                        }
                        Confirm
                    </Button>
                    <Button variant="light" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
                </Modal>
            );
        }
        return <></>;
    }

    const getModalStateForOrder = (order, callback) => {

        let msg = '';

        if ( order ) {
            msg = 'Please confirm the following information about your order to send ' + order.quantity + ' tokens for redemption. ' +
            'IMPORTANT! For your security, make sure that BOTH the order ID and the shipping postal code match the email you received ' +
            'when you placed the redemption order. By clicking on "Confirm", you will transfer ' + order.quantity + ' tokens for fulfillment ' + 
            'of this order.';
        }

        const template = {
            type:'success',
            title:'Confirm Sending Tokens', 
            error:'', 
            msg: msg, 
            show: true, 
            payload: order, 
            onConfirm: callback
        }

        return template;
    }

    const clickSendTokens = async (order) => {
        const newModalState = getModalStateForOrder(order, clickSendTokensConfirm)
        setModalState(newModalState);
    }

    const clickSendTokensConfirm = async (order) => {
        let orderIdInput = document.getElementById('order-id');
        let postalCodeInput = document.getElementById('postalcode');

        let res; // our response from the verify call
        let resError = ''; // any error message from the verify call
        try {
            res = await axios.post(process.env.REACT_APP_REDEEM_APP_SERVER + '/order/verify', {
                id: orderIdInput.value,
                postcode: postalCodeInput.value
            });    
        } catch (e) {
            console.log(`Unable to connect to ${process.env.REACT_APP_REDEEM_APP_SERVER}. ${e.toString()}`)
            resError = e.toString();
            res = null;
        }

        // If the order ID doesn't match, clear out the response and set an error to be displayed
        if ( order.orderid != orderIdInput.value ) {
            console.log(`orderid mismatch... expected ${order.orderid} but received ${orderIdInput.value}`);
            res = false;
            resError = 'Error: Order ID Mismatch';
        }        

        // If the order can't be verified, do not attempt to send the tokens
        if (! res || ( ! res.data || ! res.data.verified ) ) {
            const newModalState = getModalStateForOrder(order, clickSendTokensConfirm)
            newModalState.orderid = orderIdInput.value;
            newModalState.postalcode = postalCodeInput.value;
            newModalState.error = 'Warning! The postal code you provided does not match this redemption order. Do not proceed with this transfer unless you can confirm the order details. ' + resError;
            setModalState(newModalState)
            setModalLoadingState(false)
            return false;
        }

        let asset = Asset.from(order.quantity);
        let actions = [{
            account: process.env.REACT_APP_CONTRACT_CODE,
            name: 'transfer',
            authorization: [{
                actor: ual.activeUser.accountName,
                permission: ual.activeUser.requestPermission,
            }],
            data: {
                from: ual.activeUser.accountName,
                to: process.env.REACT_APP_REDEEM_DEP_DELEGATE,
                quantity: asset.toString(),
                memo: 'redeem:' + order.orderid
            }
        }];

        try {
            const res = await ual.activeUser.signTransaction(
                {
                    actions: actions
                },
                {
                    blocksBehind: 3,
                    expireSeconds: 60
                }
            );
            setLastTxn(res);
            let newModalState = Object.assign({},modalState);
            newModalState.show = false;
            setModalState(newModalState);
        } catch (e) {
            console.log(e);
            const newModalState = Object.assign({}, modalState);
            newModalState.show = false;
            setModalState(newModalState);
            setAlertState({type:'danger',msg: e.toString(),show:true});
            // If you want display the message to the user, remove the 'assertion failure' part of the error message:
            // message.textContent = e.message.replace('assertion failure with message:','');
            // Then display the message where you like
        }
    


        console.log(res.data);

        console.log('clickSendTokensConfirm not yet implemented')
        setModalLoadingState(false)
        return false;
    }

    useEffect(() => {

        let newState = Object.assign({},state);
        let account = (ual.activeUser && ual.activeUser.accountName) ? ual.activeUser.accountName : '';

        // Get system token balance
        rpc.get_currency_balance('eosio.token', user, process.env.REACT_APP_TOKEN_SYMBOL).then(res => {
            let waxBalance = 0;
            if(res.length) {
                waxBalance = res[0].split(' ')[0];
                waxBalance = Number(waxBalance)

                newState.balance = Math.round(waxBalance);
            }

            // Get token balance
            rpc.get_currency_balance(process.env.REACT_APP_CONTRACT_CODE, user, process.env.REACT_APP_TOKEN_SYMBOL).then(async res => {
                let tokenBalance = 0;
                if(res.length) {
                    tokenBalance = res[0].split(' ')[0];
                    tokenBalance = Number(tokenBalance)

                    newState.tokenBalance = tokenBalance;
                }

                if ( user ) {


                    console.log(`Loading redemption balances for user ${user}`)
                    // Get the redemption balances
                    const code = process.env.REACT_APP_CONTRACT_CODE;
                    const scope = process.env.REACT_APP_TOKEN_SYMBOL;
                    const table = 'redemption';
                
                    // Query the data table for the single id (both lower and upper bound)
                    let request = {
                        json: true,               // Get the response as json
                        code: code,               // Contract that we target
                        scope: scope,             // Account that owns the data
                        table: table,             // Table name
                        limit: 100,               // Maximum number of rows that we want to get PER REQUEST PAGE
                        reverse: false,           // Optional: Get reversed data
                        show_payer: false,        // Optional: Show ram payer

                        // TODO: Fix this so we only pull relevant redemptions!
                        // index_position: 2,
                        // key_type: 'i128',
                        // lower_bound: `0x${getLowerBound(user)}`,
                        // upper_bound: `0x${getUpperBound(user)}`
                    };
                    await rpc.get_table_rows(request).then(res => {
                        console.log(res.rows);
                        const newOrders = [];
                        // TODO: This checks to make sure we only grab orders for the user
                        // however, there is a secondary index on the table to do this
                        // Just having trouble getting the bounds to work correctly.
                        for ( let i = 0; i < res.rows.length; i++ ) {
                            const o = res.rows[i];
                            if ( o.account == user ) {
                                newOrders.push(o);
                            }
                        }
                        setOrders(newOrders);
                    }).catch(error => console.log(error));
                }

                setState(newState);

            }).catch(error => console.log(error));

        }).catch(error => console.log(error));
            
    }, [ual,rpc,user,lastTxn]);

    let loggedInContent;

    // Only allow sale to be displayed if all of these are true
    let allowRewards = ( ual.activeUser && ual.activeUser.accountName  );

    if ( allowRewards ) {

        loggedInContent = (
            <Container fluid className="px-0">
                <div className="section-divider">
                    <FontAwesomeIcon icon={faSquare} size="3x" />
                </div>
                <Container fluid className="rewards-add-token px-0">
                    <Container fluid className="px-0" id="add-token">
                        <Container fluid className="px-0">
                            <Container className="pt-5">
                                <Row className="pt-5">
                                    <Col md={12}>
                                        <h2><span>Set up Your Wallet</span></h2>
                                    </Col>
                                </Row>
                                <WalletInstructions />
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>
        );
    }

    let OrderBlock;

    if ( orders.length ) {
        OrderBlock = (
            orders.map(function(o,i){
                let received = ( o.quantity === o.received ? true: false );
                if ( received ) {
                    return <Row className="justify-content-center my-5" key={o.orderid}>
                        <Col xs={12} md={8}>
                            <Card className="text-black p-0">
                                <Card.Header>Redemption Order #{o.orderid}</Card.Header>
                                <Card.Body>
                                    <Card.Title>Redeeming {o.quantity}</Card.Title>
                                    <Card.Text>
                                        We have received {o.received} tokens you transferred to complete this redemption.
                                        The order is being transmitted to our fulfillment center, at which point your order
                                        will disappear from this list. No further action is required on your part.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                } else {
                    return <Row className="justify-content-center my-5" key={o.orderid}>
                        <Col xs={12} md={8}>
                            <Card className="text-black p-0">
                                <Card.Header>Redemption Order #{o.orderid}</Card.Header>
                                <Card.Body>
                                    <Card.Title>Redeem {o.quantity}</Card.Title>
                                    <Card.Text>
                                        We are awaiting your transfer of these {process.env.REACT_APP_TOKEN_SYMBOL} tokens in order to complete this redemption.
                                        Your redemption order will NOT be shipped UNTIL you use this form to transfer exactly {o.quantity} from your account to 
                                        the depository. To begin, please click "Send Tokens" below.
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <Button variant="warning" size="" className="me-3" onClick={()=>{clickSendTokens(o)}}>Send Tokens</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                }
            })
        );
    }
    else if ( orders.length == 0 && user ) {
        OrderBlock = (
            <Row className="justify-content-center">
                <Col xs={12} md={8}>
                    <Card className="text-black p-0">
                        <Card.Header>No Redemption Orders</Card.Header>
                        <Card.Body>
                            <Card.Title>No Orders Found</Card.Title>
                            <Card.Text>
                                We don't currently have any redemption orders for your account. 
                                If you just placed one, check back in a minute or two. 
                                To place a new redemption order, follow the link below.
                            </Card.Text>
                            <Button variant="warning" className="me-3" href="/crowdsale">Buy {process.env.REACT_APP_TOKEN_SYMBOL}</Button>
                            <Button variant="success" href={process.env.REACT_APP_REDEEM_BEGIN_URL}>Begin</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
    }
    else {
        OrderBlock = (
            <Row className="rewards-partners pt-5">
                <Col xs={12} md={4}>
                    <Image fluid={true} src={process.env.REACT_APP_REWARDS_IMG} />
                </Col>
                <Col xs={12} md={8}>
                    <h2 className="mt-4">Get Started</h2>
                    <p>
                    To get started redeeming your {process.env.REACT_APP_TOKEN_NAME} tokens, you will 
                    first need to connect your wallet here on this page. Click the button below to start.
                    </p>
                    <Button size="lg" variant="warning" onClick={ual.showModal}>Login</Button>
                </Col>
            </Row>
        );
    }

    return (
        <Container fluid className="px-0">
            <AlertDismissible />
            <ConfirmOrderModal />
            <Container fluid className="px-0 mb-5 rewards-hero">
                <Container className="pt-5">
                    <h1 className="pt-5 mt-5">{pageTitle}</h1>
                    <p className="text-center description">{pageDescription}</p>
                </Container>
            </Container>
            <Container fluid className="my-5">
                {OrderBlock}
            </Container>
            {loggedInContent}
        </Container>
    )
}

export default Redeem