import { React, useContext, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import { UALContext } from "ual-reactjs-renderer";
import Blockchains from './Blockchains/Blockchains'

import './Menu.css'

const Menu = (props) => {

  let ual = useContext(UALContext);
  let user = (ual.activeUser && ual.activeUser.accountName) ? ual.activeUser.accountName : '';
  let click;
  let authNavItem;

  const { chain } = props;

  const [balance, setBalance] = useState(null);
  const [tokenBalance, setTokenBalance] = useState(null);

  const logout = () => {
    ual.logout();
    // Ensure the UI is updated for logged out state
    window.location.reload(false);
  };

  if (ual.activeAuthenticator) {
      click = () => logout();

      let rpc;

      if (ual.activeUser && ual.activeUser.rpc) {
          rpc = ual.activeUser.rpc;
      }
      else if (ual.activeUser && ual.activeUser.wax && ual.activeUser.wax.rpc) {
          rpc = ual.activeUser.wax.rpc;
      }

      if (rpc) {
          rpc.get_currency_balance('eosio.token', user, process.env.REACT_APP_CURRENCY_SYMBOL).then(res => {
              if(res.length) {
                  let waxBalance = res[0].split(' ')[0];
                  waxBalance = Number(waxBalance)

                  setBalance(Math.round(waxBalance).toString() + ' WAX');
              }
              else {
                  setBalance('0 WAX');
              }
          }).catch(error => console.log(error));

          // Get token balance
          rpc.get_currency_balance(process.env.REACT_APP_CONTRACT_CODE, user, process.env.REACT_APP_TOKEN_SYMBOL).then(res => {
            if(res.length) {
                let tokenBalance = res[0].split(' ')[0];
                tokenBalance = Number(tokenBalance)

                setTokenBalance(tokenBalance.toString() + ' ' + process.env.REACT_APP_TOKEN_SYMBOL);
            }
            else {
                setTokenBalance("0 " + process.env.REACT_APP_TOKEN_SYMBOL);
            }
        }).catch(error => console.log(error));
      }
      authNavItem = (
        <LinkContainer to='#'>
          <div>
            <button type='button' className='btn btn-success mx-2' onClick={click}>
              Logout
            </button>
            <div className="navbar-item mx-3 align-middle d-inline-block text-center account-name">{user} <span className='d-md-none d-lg-inline-block'>({balance} | {tokenBalance})</span></div>
          </div>
        </LinkContainer>  
      );

  }
  else {
      click = () => ual.showModal();
      authNavItem = (
        <LinkContainer to='#'>
          <button type='button' className='btn btn-success mx-2' onClick={click}>
            Login
          </button>
        </LinkContainer>
      );      
  }

  let homeUrl = process.env.REACT_APP_HOME_URL ? process.env.REACT_APP_HOME_URL : '/';
  let logo = process.env.REACT_APP_LOGO ? process.env.REACT_APP_LOGO : '/react.svg';

  return (
    <Navbar collapseOnSelect fixed="top" expand="md" bg="dark" variant="dark">
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Brand href={homeUrl}>
            <img
              src={logo}
              className="d-inline-block align-top"
              alt={process.env.REACT_APP_UAL_APP_NAME}
            />
          </Navbar.Brand>
          <Navbar.Collapse id='responsive-navbar-nav' className="justify-content-start">
            <Nav className="text-center">
              <Nav.Link className="me-2" href='/crowdsale'>Crowdsale</Nav.Link>
              <Nav.Link className="me-2" href='/rewards'>Rewards</Nav.Link>
              <Nav.Link className="me-2" href='/redeem'>Redeem</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <div className="d-flex text-center justify-content-center">
              <Blockchains chain={chain} className="me-2" />          
              {authNavItem}
          </div>
    </Navbar>
  )
}

export default Menu
