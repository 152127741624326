const Footer = () => {
  const year = new Date().getFullYear()

  let lines = process.env.REACT_APP_COPYRIGHT.split('\\n');

  for ( let i = 0; i < lines.length; i++ ) {
    if ( i === 0 ) {
      lines[i] = 'Copyright © ' + year + ' ' + lines[i]
    }
  }
  
  return (
    <>
      <footer className='text-center text-capitalize'>
        {lines.map((line, i) => ( 
            <p key={i}>{line}</p>
          ))}
      </footer>
    </>
  )
}

export default Footer