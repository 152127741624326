import { React, useContext, useEffect, useState } from 'react';
import './Rewards.css'

import Programs from '../rewards'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

import { UALContext } from "ual-reactjs-renderer";
import { Asset } from '@greymass/eosio';

import {Container,Row,Col,Button,Image,Modal} from 'react-bootstrap'

import WalletInstructions from '../components/WalletInstructions'

const Rewards = ({rpc}) => {

    let ual = useContext(UALContext);

    const [state, setState] = useState({balance:0, tokenBalance:0, rewards:{}});
    const [alertState, setAlertState] = useState({type:'danger',msg:'',show:false});
    const [lastTxn, setLastTxn] = useState();

    let user = (ual.activeUser && ual.activeUser.accountName) ? ual.activeUser.accountName : '';

    let unitLabel = process.env.REACT_APP_UNIT_LABEL;
    if ( ! unitLabel ) { unitLabel = 'tokens'; }

    // page content
    const pageTitle = 'Earn ' + process.env.REACT_APP_TOKEN_NAME + ' Rewards'
    const pageDescription = process.env.REACT_APP_TOKEN_REWARDS_DESCRIPTION

    const AlertDismissible = () => {
        let newAlertState = Object.assign({},alertState);     

        const handleClose = () => { newAlertState.show = false; setAlertState(newAlertState);};
        const msg = alertState.msg.replace('Error: assertion failure with message: ','');
      
        if (alertState.show) {
            return (
                <Modal show={alertState.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Oops! Something Happened.</Modal.Title>
                </Modal.Header>
                <Modal.Body>{msg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    Close
                    </Button>
                </Modal.Footer>
                </Modal>
            );
        }
        return <></>;
    }

    const getRewardsButton = (o) => {
        if ( ! allowRewards ) {
            return <Button className="" size="lg" variant="success" onClick={ual.showModal}>Login to Access</Button>
        }
        else {
            let rewardBalance = 0;
            let rewardAsset;
            if (state.rewards[o.account])
            {
                rewardAsset = Asset.from(state.rewards[o.account]);
                rewardBalance = rewardAsset.value;
            }
    
            if ( rewardBalance && rewardAsset ) {
                const id = o.account + '-claim-button';
                return <Button className="" id={id} size="lg" variant="success" onClick={() => clickClaim(o.account)}>Claim {rewardAsset.toString()}</Button>
            }
            else {
                return <Button className="" size="lg" variant="success" disabled>No Rewards to Claim</Button>
            }
        }
    }

    const getProgram = (account) => {
        const chainId = process.env.REACT_APP_CHAIN.replace('-','');
        for ( let i = 0; Programs[chainId].length; i++ ) {
            let program = Programs[chainId][i];
            if ( program.account === account ) {
                return program;
            }
        }

        return false;
    }

    const scrollToAdd = () => {
        document.getElementById("add-token").scrollIntoView();
    }

    const clickClaim = async (account) => {
        let reward = state.rewards[account];
        let program = getProgram(account);

        if ( ! reward || ! program ) { 
            console.log('clickClaim called with zero reward or no valid program.');
            return;
        }

        let asset = Asset.from(reward);

        let actions = [{
            account: program.account,
            name: program.action,
            authorization: [{
                actor: ual.activeUser.accountName,
                permission: ual.activeUser.requestPermission,
            }],
            data: {
                owner: ual.activeUser.accountName,
                token_to_withdraw: asset.toString()
            }
        }];

        try {
            const res = await ual.activeUser.signTransaction(
                {
                    actions: actions
                },
                {
                    blocksBehind: 3,
                    expireSeconds: 60
                }
            );
            setLastTxn(res);
            document.getElementById(account+'-claim-button').disabled = true;
            document.querySelector('#'+account+'-claim-button').innerHTML = 'No Rewards to Claim'
            scrollToAdd();
        } catch (e) {
            console.log(e);
            setAlertState({type:'danger',msg: e.toString(),show:true});
            // If you want display the message to the user, remove the 'assertion failure' part of the error message:
            // message.textContent = e.message.replace('assertion failure with message:','');
            // Then display the message where you like
        }

    }

    useEffect(() => {

        let newState = Object.assign({},state);

        // Get system token balance
        rpc.get_currency_balance('eosio.token', user, process.env.REACT_APP_TOKEN_SYMBOL).then(res => {
            let waxBalance = 0;
            if(res.length) {
                waxBalance = res[0].split(' ')[0];
                waxBalance = Number(waxBalance)

                newState.balance = Math.round(waxBalance);
            }

            // Get token balance
            rpc.get_currency_balance(process.env.REACT_APP_CONTRACT_CODE, user, process.env.REACT_APP_TOKEN_SYMBOL).then(async res => {
                let tokenBalance = 0;
                if(res.length) {
                    tokenBalance = res[0].split(' ')[0];
                    tokenBalance = Number(tokenBalance)

                    newState.tokenBalance = tokenBalance;
                }

                if ( user ) {
                    const chainId = process.env.REACT_APP_CHAIN.replace('-','');

                    for ( let i = 0; i < Programs[chainId].length; i++ ) {
                        let r = Programs[chainId][i];
    
                        // Get the reward balances
                        const code = r.account;
                        const scope = r.account;
                        const table = r.table;
                    
                        // Query the data table for the single id (both lower and upper bound)
                        let request = {
                            json: true,               // Get the response as json
                            code: code,               // Contract that we target
                            scope: scope,             // Account that owns the data
                            table: table,             // Table name
                            limit: 10,             // Maximum number of rows that we want to get PER REQUEST PAGE
                            reverse: false,           // Optional: Get reversed data
                            show_payer: false,         // Optional: Show ram payer
                            lower_bound: user,
                            upper_bound: user
                        };
            
                        await rpc.get_table_rows(request).then(res => {
                            let newState = Object.assign({},state);
                            newState.rewards[r.account] = 0;
                            if ( res && res.rows && res.rows.length )
                            {
                                if ( res.rows[0].unclaimed ) {
                                    newState.rewards[r.account] = res.rows[0].unclaimed;
                                }
                            }
                        }).catch(error => console.log(error));
                    }    
                }

                setState(newState);

            }).catch(error => console.log(error));

        }).catch(error => console.log(error));
            
    }, [rpc,user,lastTxn]);

    let loggedInContent;

    // Only allow sale to be displayed if all of these are true
    let allowRewards = ( ual.activeUser && ual.activeUser.accountName  );

    if ( allowRewards ) {

        loggedInContent = (
            <Container fluid className="px-0">
                <div className="section-divider">
                    <FontAwesomeIcon icon={faSquare} size="3x" />
                </div>
                <Container fluid className="rewards-add-token px-0">
                    <Container fluid className="px-0" id="add-token">
                        <Container fluid className="px-0">
                            <Container className="pt-5">
                                <Row className="pt-5">
                                    <Col md={12}>
                                        <h2><span>Set up Your Wallet</span></h2>
                                    </Col>
                                </Row>
                                <WalletInstructions />
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>
        );
    }

    const chainId = process.env.REACT_APP_CHAIN.replace('-','');

    return (
        <Container fluid className="px-0">
            <AlertDismissible />
            <Container fluid className="px-0 mb-5 rewards-hero">
                <Container className="pt-5">
                    <h1 className="pt-5 mt-5">{pageTitle}</h1>
                    <p className="text-center description">{pageDescription}</p>
                    {
                        Programs[chainId].map(function(o,i){
                            return <Row className="rewards-partners pt-5">
                                <Col xs={12} md={4}>
                                    <Image className="px-5" fluid={true} src={o.img} />
                                </Col>
                                <Col xs={12} md={8}>
                                    <h2 className="mt-4">{o.title}</h2>
                                    <p>{o.description}</p>
                                    <Button className="rewards-partner-btn" size="lg" variant="warning" href={o.url}>Learn More</Button> {getRewardsButton(o)}
                                </Col>
                            </Row>
                        })
                    }
                    <Row className="rewards-partners pt-5">
                        <Col xs={12} md={4}>
                            <Image fluid={true} src={process.env.REACT_APP_REWARDS_IMG} />
                        </Col>
                        <Col xs={12} md={8}>
                            <h2 className="mt-4">Partner With Us</h2>
                            <p>Interested in adding {process.env.REACT_APP_TOKEN_NAME} rewards to your project?
                              Offer your customers the unique opportunity to earn rewards, whether through staking, 
                              gaming, or transactions. The sky is the limit! We offer flexible development 
                              options, or can work directly with your own developers to build the perfect rewards
                              program for your project. Contact us today for a free consultation and see how you 
                              can add value to your project with {process.env.REACT_APP_TOKEN_NAME} rewards.
                            </p>
                            <Button size="lg" variant="warning" href={process.env.REACT_APP_REWARDS_CONTACT_URL}>Contact Us</Button>
                        </Col>
                    </Row>
                </Container>
            </Container>
            {loggedInContent}
        </Container>
    )
}

export default Rewards