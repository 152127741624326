const rewards = {
    wax: [
        { 
            "img": "https://ibmpc.mypinata.cloud/ipfs/Qme9vzyPEAyJ8sA75FCHjQyznJgHpLsfTAYBf3crDv5zag",
            "url": "https://ibm.thepc.io/",
            "account": "ibmpcstkrstk", 
            "action": "stakerclaim", 
            "table": "rewards", 
            "title": "IBM PC NFT Stickers",
            "description": "The original IBM PC was the exciting new computer of the 80s, it was also a versatile workhorse. The IBM PC NFT Experience designed their Staking Sticker Series with that in mind. Don't just own a piece of history when you can put it to work and earn REAL SILVER!"
        }
    ],
    eos: [
    ],
    waxtest: [
        { 
            "img": "https://ibmpc.mypinata.cloud/ipfs/Qme9vzyPEAyJ8sA75FCHjQyznJgHpLsfTAYBf3crDv5zag",
            "url": "https://ibm.thepc.io/",
            "account": "ibmpcstkrstk", 
            "action": "stakerclaim", 
            "table": "rewards", 
            "title": "IBM PC NFT Stickers",
            "description": "The original IBM PC was the exciting new computer of the 80s, it was also a versatile workhorse. The IBM PC NFT Experience designed their Staking Sticker Series with that in mind. Don't just own a piece of history when you can put it to work and earn REAL SILVER!"
        }
    ],
    jungle3: [
    ]
}

export default rewards
