import { React, useContext, useEffect, useState } from 'react';
import './Crowdsale.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

import { UALContext } from "ual-reactjs-renderer";
import { Asset } from '@greymass/eosio';

import {Container,Row,Col,Button,ProgressBar,Alert,Modal} from 'react-bootstrap'

import WalletInstructions from '../components/WalletInstructions'

// const endpoint = `${process.env.REACT_APP_RPC_PROTOCOL}://${process.env.REACT_APP_RPC_HOST}:${process.env.REACT_APP_RPC_PORT}`;
// const rpc = new JsonRpc(endpoint);

const get_table_rows = async (rpc, code, scope, table, lower, upper, limit) => {
    // If no limit is set, assume 50
    if (!limit) {
        limit = 50;
    }

    let request = {
        json: true,               // Get the response as json
        code: code,               // Contract that we target
        scope: scope,             // Account that owns the data
        table: table,             // Table name
        limit: limit,             // Maximum number of rows that we want to get PER REQUEST PAGE
        reverse: false,           // Optional: Get reversed data
        show_payer: false         // Optional: Show ram payer
    };

    if (lower) {
        request.lower_bound = lower;
    }
    if (upper) {
        request.upper_bound = upper;
    }

    let rows = [];

    while (true) {
        if (rpc) {
            let res = await rpc.get_table_rows(request);

            rows = rows.concat(res.rows);

            if (res.more) {
                request.lower_bound = res.next_key;
            }
            else {
                break;
            }
        }
    }
    return rows;
}

const getCrowdsaleInfo = async (rpc) => {

    const code = process.env.REACT_APP_CONTRACT_CODE
    const scope = process.env.REACT_APP_CONTRACT_CODE
    const table = process.env.REACT_APP_CONTRACT_DATA_TABLE
    const id = process.env.REACT_APP_TOKEN_SYMBOL

    // Query the data table for the single id (both lower and upper bound)

    let rows = await get_table_rows(rpc,code, scope, table, id, id);

    if (rows && rows.length) {
        return rows[0];
    }

    else {
        // TODO: This is technically an error as the drop doesn't exist
        // Consider throwing an exception if this is important
        return {};
    }
}

const getPriceOracle = async (rpc) => {

    const code = 'delphioracle';
    const scope = 'waxpusd';
    const table = 'datapoints';

    // Query the data table for the single id (both lower and upper bound)

    let rows = await get_table_rows(rpc,code, scope, table);

    if (rows && rows.length) {
        return rows[0];
    }

    else {
        console.log('Failed to get rate from delphioracle')
        // TODO: This is technically an error as the drop doesn't exist
        // Consider throwing an exception if this is important
        return {};
    }
}

const Crowdsale = ({rpc}) => {

    let ual = useContext(UALContext);

    const [state, setState] = useState({fee:'',rate:'',maxIssuance:'',remaining:0,progress:0, currency:0, tokens:0, minSpend:1, maxSpend:100});
    const [alertState, setAlertState] = useState({type:'danger',msg:'',show:false});
    const [lastTxn, setLastTxn] = useState();

    let user = (ual.activeUser && ual.activeUser.accountName) ? ual.activeUser.accountName : '';

    let unitLabel = process.env.REACT_APP_UNIT_LABEL;
    if ( ! unitLabel ) { unitLabel = 'tokens'; }

    // page content
    const pageTitle = 'Introducing ' + process.env.REACT_APP_TOKEN_NAME
    const pageDescription = process.env.REACT_APP_TOKEN_DESCRIPTION

    const AlertDismissible = () => {
        let newAlertState = Object.assign({},alertState);     

        const handleClose = () => { newAlertState.show = false; setAlertState(newAlertState);};
        const msg = alertState.msg.replace('Error: assertion failure with message: ','');
      
        if (alertState.show) {
            return (
                <Modal show={alertState.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Oops! Something Happened.</Modal.Title>
                </Modal.Header>
                <Modal.Body>{msg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    Close
                    </Button>
                </Modal.Footer>
                </Modal>
            );
        }
        return <></>;
    }

    let calcLocked = false;
    
    const calcCurrency = async (e) => {
        if ( calcLocked ) { return; }

        let tokens = parseFloat(e.target.value);
        if ( ! tokens || tokens <= 0 ) { e.preventDefault(); return; }

        calcLocked = true;

        let amt = currencyFromTokens(tokens);

        let newState = Object.assign({},state);
        newState['currency'] = amt;
        newState['tokens'] = tokens;
        setState(newState);
        calcLocked = false;
    }
    
    const calcTokens = (e) => {
        if ( calcLocked ) { return; }

        let currency = parseFloat(e.target.value);
        if ( ! currency || currency <= 0 ) { e.preventDefault(); return; }

        calcLocked = true;
    
        let amt = tokensFromCurrency(currency);

        let newState = Object.assign({},state);
        newState['currency'] = currency;
        newState['tokens'] = amt;
        setState(newState);
        calcLocked = false;
    }

    const tokensFromCurrency = (currency, rate, fee) => {
        if ( ! rate ) { rate = state.rate; }
        if ( ! fee ) { fee = state.fee; }
        return Math.floor( Math.pow(10,4) * currency * state.rate / state.fee ) / Math.pow(10,4);
    }

    const currencyFromTokens = (tokens, rate, fee) => {
        if ( ! rate ) { rate = state.rate; }
        if ( ! fee ) { fee = state.fee; }
        return Math.ceil( Math.pow(10,4) * tokens * state.fee / state.rate ) / Math.pow(10,4);
    }

    const clickBuy = async () => {
        // Get the currency amount to pay
        let asset = Asset.from(process.env.REACT_APP_CURRENCY_TEMPLATE);
        asset.value = state.currency;

        let actions = [{
                account: 'eosio.token',
                name: 'transfer',
                authorization: [{
                actor: ual.activeUser.accountName,
                permission: ual.activeUser.requestPermission,
            }],
            data: {
                from: ual.activeUser.accountName,
                to: process.env.REACT_APP_CONTRACT_CODE,
                quantity: asset.toString(),
                memo: process.env.REACT_APP_TOKEN_SYMBOL
            }
        }];

        try {
            const res = await ual.activeUser.signTransaction(
                {
                    actions: actions
                },
                {
                    blocksBehind: 3,
                    expireSeconds: 30
                }
            );
            setLastTxn(res);
            scrollToAdd();

        } catch (e) {
            console.log(e);
            setAlertState({type:'danger',msg: e.toString(),show:true});
            // If you want display the message to the user, remove the 'assertion failure' part of the error message:
            // message.textContent = e.message.replace('assertion failure with message:','');
            // Then display the message where you like
        }

    }

    const scrollToBuy = () => {
        document.getElementById("buy-now").scrollIntoView();
    }

    const scrollToAdd = () => {
        document.getElementById("add-token").scrollIntoView();
    }

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    useEffect(() => {

        getCrowdsaleInfo(rpc).then(res => {
            const asset = Asset.from(res.certified);
            let certifiedBalance = Math.floor(asset.value);
            let certified = Math.floor(asset.value).toString() + ' ' + asset.symbol.code;


            let fee = 0;

            if ( res.eos_per_token ) {
                let feeAsset = Asset.from(res.eos_per_token);
                fee = Number(feeAsset.value);
            }
            else {
                console.log('Fee is not set on contract!')
            }

            rpc.get_currency_balance(process.env.REACT_APP_CONTRACT_CODE, process.env.REACT_APP_CONTRACT_CODE, process.env.REACT_APP_TOKEN_SYMBOL).then((balance) => {
                if ( balance.length ) {
                    const asset = Asset.from(balance[0]);
                    let remaining = asset.value;
                    let progress = Math.floor(100 * remaining / certifiedBalance);
                    getPriceOracle(rpc).then(res => {
                        let rate = (res.median / Math.pow(10,4)).toFixed(4);

                        let newState = {
                            maxIssuance: certified,
                            fee: fee,
                            remaining: remaining,
                            progress: progress,
                            rate: rate,
                            tokens: 1,
                            minSpend: 1,
                            maxSpend: 100
                        };

                        newState.currency = Math.floor( Math.pow(10,4) * newState.tokens * newState.fee / newState.rate ) / Math.pow(10,4);

                        // Set the state now, as the next step is optional (e.g. they may not be logged in)
                        setState(newState);

                        if ( user ) {
                            rpc.get_currency_balance(process.env.REACT_APP_CONTRACT_CODE, user, process.env.REACT_APP_TOKEN_SYMBOL).then((balance) => {
                                if ( balance.length ) {
                                    const asset = Asset.from(balance[0]);
                                    let maxSpend = asset.value;
                                    newState.maxSpend = maxSpend;
                                    setState(newState);
                                }
                            });
                        }

                    }).catch(error => console.log(error));
                }
            });
        }).catch(error => console.log(error));            
    }, [rpc, user, lastTxn]);

    let loggedInContent;
    let click = () => ual.showModal();

    // Only allow sale to be displayed if all of these are true
    let allowSale = ( ual.activeUser && ual.activeUser.accountName && state.fee && state.rate && state.remaining >= 0 );

    if ( allowSale ) {

        loggedInContent = (
            <Container fluid className="px-0">
                <div className="section-divider">
                    <FontAwesomeIcon icon={faSquare} size="3x" />
                </div>
                <Container fluid className="crowdsale-step-one px-0">
                    <Container fluid className="px-0" id="buy-now">
                        <Container fluid className="px-0">
                            <Container className="pt-5">
                                <Row className="pt-5">
                                    <Col md={12}>
                                        <h2 className="fancy"><span>Buy Now</span></h2>
                                        <p className="description">{process.env.REACT_APP_CROWDSALE_STEP_1}</p>
                                    </Col>
                                </Row>
                                <Row className="pt-5 gx-5">
                                    <Col xs={12} md={6}>
                                        <p className="text-center">{capitalize(unitLabel)} to Buy</p>
                                        <input className="form-control form-control-lg calc-field text-center" type="number" value={state.tokens} id="tokens" name="tokens" onChange={(e) => calcCurrency(e)} />
                                        <div className="text-center accent strong"><label htmlFor="tokens">{process.env.REACT_APP_TOKEN_SYMBOL}</label></div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <p className="text-center">{process.env.REACT_APP_CURRENCY_SYMBOL} to Spend</p>
                                        <input className="form-control form-control-lg calc-field text-center" type="number" id="currency" name="currency" value={state.currency} onChange={(e) => calcTokens(e)} />
                                        <div className="text-center accent strong"><label htmlFor="currency">{process.env.REACT_APP_CURRENCY_SYMBOL}</label></div>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center mt-5">
                                    <Col md={8}>
                                        <div className="d-grid gap-2">
                                           <input type="range" className="form-control-range form-range" id="to-spend-range" value={state.tokens} min={state.minSpend} max={state.maxSpend} step="1" onChange={(e) => calcCurrency(e)} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center mt-5">
                                    <Col md={6}>
                                        <div className="d-grid gap-2">
                                            <Button size="lg" variant="warning" onClick={clickBuy}>Purchase Now <i className="fa fa-check"></i></Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Container>
                </Container>
                <Container fluid className="crowdsale-add-token px-0">
                    <Container fluid className="px-0" id="add-token">
                        <Container fluid className="px-0">
                            <Container className="pt-5">
                                <Row className="pt-5">
                                    <Col md={12}>
                                        <h2><span>Set up Your Wallet</span></h2>
                                    </Col>
                                </Row>
                                <WalletInstructions />
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>
        );
        click = () => scrollToBuy();
    }

    return (
        <Container fluid className="px-0">
            <AlertDismissible />
            <Container fluid className="px-0 crowdsale-hero">
                <Container className="pt-5">
                    <h1 className="pt-5 mt-5">{pageTitle}</h1>
                    <p className="text-center description">{pageDescription}</p>

                    <Row className="crowdsale-intro pt-5">
                        <Col xs={12} md={6}>
                            <div className="d-grid gap-2">
                                <Button size="lg" variant="warning" onClick={click}>Get Started <i className="fa fa-check"></i></Button>
                            </div>
                            <h4 className="mt-4">Limited Supply!</h4>
                            <p>Only {state.maxIssuance} {unitLabel} total are available in this issuance, and {Math.round(state.remaining)} remain.</p>
                            <ProgressBar animated now={state.progress} variant="warning"></ProgressBar>
                            <p className="text-right accent strong">{state.maxIssuance} Hard Cap</p>
                        </Col>
                        <Col xs={12} md={6}>
                            <p>The exchange rate for the crowdsale is calculated using an exchange rate maintained 
                            by price oracles (<a href={process.env.REACT_APP_BLOKS_URL + '/account/delphioracle'}>delphioracle</a>). 
                            The current rates used for the exchange rate are:</p>
                        
                            <Alert variant="light" className="info-box">
                                <h2 className="text-center accent">{process.env.REACT_APP_TOKEN_PRICE_SYMBOL}{state.fee}&nbsp;{process.env.REACT_APP_TOKEN_PRICE_UNIT} / {process.env.REACT_APP_TOKEN_SYMBOL}</h2>
                                <h2 className="text-center accent">{process.env.REACT_APP_TOKEN_PRICE_SYMBOL}{state.rate}&nbsp;{process.env.REACT_APP_TOKEN_PRICE_UNIT} / {process.env.REACT_APP_CURRENCY_SYMBOL}</h2>
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            </Container>
            {loggedInContent}
        </Container>
    )
}

export default Crowdsale