import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css'

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// UAL
import {UALProvider, withUAL} from 'ual-reactjs-renderer';
import { JsonRpc } from 'eosjs';
import {Wax} from '@eosdacio/ual-wax';
import {Anchor} from 'ual-anchor';

import chains from './components/Blockchains/assets/blockchains'
import { find } from 'lodash'

const chain = find(chains, {key: process.env.REACT_APP_CHAIN })

const endpoint = `${chain.rpcEndpoints[0].protocol}://${chain.rpcEndpoints[0].host}:${chain.rpcEndpoints[0].port}`;
const rpc = new JsonRpc(endpoint);

const wax = new Wax([chain], {appName: process.env.REACT_APP_UAL_APP_NAME})
const anchor = new Anchor([chain], {appName: process.env.REACT_APP_UAL_APP_NAME})

const authenticators = [];

if ( process.env.REACT_APP_CHAIN === 'wax' ) {
  authenticators.push(wax)
}

authenticators.push(anchor)

const MyUALConsumer = withUAL(App)

ReactDOM.render(
  <React.StrictMode>
    <UALProvider chains={[chain]} authenticators={authenticators} appName={process.env.REACT_APP_UAL_APP_NAME}>
      <MyUALConsumer rpc={rpc} chain={chain} />
    </UALProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
