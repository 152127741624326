import { React } from 'react'
import { NavDropdown } from 'react-bootstrap';

import eosLogo from './assets/eos.png';
import fioLogo from './assets/fio.svg';
import jungleLogo from './assets/jungle.png';
import protonLogo from './assets/proton.png';
import telosLogo from './assets/telos.png';
import waxLogo from './assets/wax.png';

import blockchains from './assets/blockchains.json';
import { find } from 'lodash';

import './Blockchains.css'

const logoMap = {
  // Testnets
  "0db13ab9b321c37c0ba8481cb4681c2788b622c3abfd1f12f0e5353d44ba6e72": eosLogo,
  "b20901380af44ef59c5918439a1f9a41d83669020319a80574b804a5f95cbd7e": fioLogo,
  "e70aaab8997e1dfce58fbfac80cbbb8fecec7b99cf982a9444273cbc64c41473": jungleLogo,
  "2a02a0053e5a8cf73a56ba0fda11e4d92e0238a4a2aa74fccf46d5a910746840": jungleLogo,
  "71ee83bcf52142d61019d95f9cc5427ba6a0d7ff8accd9e2088ae2abeaf3d3dd": protonLogo,
  "1eaa0824707c8c16bd25145493bf062aecddfeb56c736f6ba6397f3195f33c9f": telosLogo,
  "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12": waxLogo,
  // Production
  "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4": waxLogo,
  "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906": eosLogo,
};

const urlMap = process.env.REACT_APP_URLS ? JSON.parse(process.env.REACT_APP_URLS) : {};

const enabledChains = [];
process.env.REACT_APP_ENABLED_CHAINS.split(',').forEach((key)=> {
  let item = find(blockchains,{key:key});
  if ( item ) { enabledChains.push(item); }
});

const Blockchains = (props) => {
  const { chain } = props;
  
  const options = enabledChains.map((chain) => ({
    key: chain.key,
    text: chain.name,
    value: chain.chainId,
    image: {
      avatar: true,
      src: logoMap[chain.chainId]
    },
  }));

  // return (
  //   <DropdownButton  title={chain.name} variant="info"  data-toggle="dropdown" onSelect={function() {}} className={props.className}>
  //   {options.map(item => (
  //     <Container>
  //       <Dropdown.Item key={item.key} href={urlMap[item.key]+window.location.pathname} data-key={item.key}>
  //         <img src={logoMap[item.value]} alt={item.key + ' logo'} className="dropdown-image" /> {item.text}
  //       </Dropdown.Item>
  //     </Container>
  //   ))}
  //   </DropdownButton>
  // )
  return (
    <NavDropdown  title={chain.name} onSelect={function() {}} className={props.className}>
    {options.map(item => (
      <NavDropdown.Item key={item.key} href={urlMap[item.key]+window.location.pathname} data-key={item.key}>
        <img src={logoMap[item.value]} alt={item.key + ' logo'} className="dropdown-image" /> {item.text}
      </NavDropdown.Item>
    ))}
    </NavDropdown>
  )


}

export default Blockchains;